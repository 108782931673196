<template>
  <div class="home">
    <signin v-if="authorized==0" />
    <div class="container">
      <VideoList v-if="VideoList && (authorized==1 ||  authorized==3)" :videoslist="VideoList"/>
      <div class="row"  v-if="authorized && (authorized==2 )" style="background-color: brown;">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <div class="card card-stats">
                <div class="card-header card-header-warning card-header-icon">
                  <h3 class="card-title">
                    <small style="color:black">Limited access. You have no permission</small>
                  </h3>
                </div>
              </div>
              </div>
       </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
}
</style>

<script>
import { mapMutations,mapActions } from "vuex";
import VideoList from '@/components/ListVideos.vue'
import Signin from './Signin.vue';
export default {
  name: 'Home',
  data() {
    return {
      VideoList: false,
    }
  },
  components: {
    VideoList,
    Signin
  },
  computed: {
    authorized() {
       return this.$store.getters['getAuhtState'];
    },
  },
  mounted() {
    this.doAuthorize();
    this.getVideos()
  },
  methods: {
    ...mapActions(["doAuthorize"]),
    ...mapMutations([
      "setError",
      "setMsg"
    ]),
    getVideos() {
      fetch('/api/videos/list', {
        method: 'GET'
      })
          .then(async r => {
            let response = await r.json()
            if (response.error) {
              this.setError(response)

              return
            }
            this.setMsg('Groups, Videos obtained');

            this.VideoList = response;
          })
    }
  }
}
</script>
