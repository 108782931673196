<template>
  <div class="form-group">
    <input type="hidden" v-model="link_channel" placeholder="Channel link" @change="sendlink"/>
    <span>{{link}}</span>
  </div>
</template>


<script>

export default {
  name: 'Link',
  props: ["link", "originlink"],
  data() {
    return {
      link_channel:''
    }
  },

  methods: {
    sendlink(){
      this.$emit('link', this.link_channel);
    }
  }
};
</script>

