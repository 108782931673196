<template>
  <div>
    <ckeditor
          :editor="editor"
          v-model="localData"
          :config="editorConfig"
          @input="onInput"
          style="height: 500px; color: black;"
      ></ckeditor>
  </div>
</template>

<script>
  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  

  export default {
      name: 'CKEditor',
      
      props: {
        modelValue: String 
      },
      data() {
          return {
              editor: ClassicEditor,
              localData: this.modelValue,
              editorConfig: {
                items: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'link',
                      'bulletedList',
                      'numberedList',
                      'blockQuote',
                      'insertTable',
                      'mediaEmbed',
                      '|',
                      'undo',
                      'redo',
                      'fontColor', // Добавление команды изменения цвета шрифта
                      'fontBackgroundColor', // Добавление команды изменения фона шрифта
                      "htmlEmbed"
                    ]
                  },
                image: {
                  toolbar: [
                    'imageStyle:full',
                    'imageStyle:side',
                    '|',
                    'imageTextAlternative'
                  ]
                },
                table: {
                  contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                },
                language: 'en',
                mediaEmbed: {
                  previewsInData: true
                },
             
       
             }
      },
      watch: {
          modelValue(newValue) {
              this.localData = newValue;
          }
      },
      methods: {
          onInput() {
              this.$emit('update:modelValue', this.localData);
          }
      }
  }
</script>